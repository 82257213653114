.contactpage{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.contactpage-content{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
}
.contactpage-content form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.contactpage-content form div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
}
.contactpage-content form p{
    width: calc((100% - 30px) / 2);
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding:0;
}
.contactpage-content p label{
    width: 100%;
    display: flex;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}
.contactpage-content p label.error{
    color: red;
}
.contactpage-content p label.error::before{
    content: "x";
    color: red;
    margin-right: 10px;
}
.contactpage-content p input[type='text']{
    width: 100%;
    height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    border:1px solid rgb(220,221,222);
    border-radius: 5px;
}
.contactpage-content p input[type='text'].error{
    border: 1px solid red;
}
.contactpage-content p input[type='text']:focus{
    outline: 2px solid blue;
    outline-offset: 1px;
}
.contactpage-content p input[type='checkbox']{
    margin-right: 10px;
}
.contactpage-content label.mandatory::after,
.contactpage-question label.mandatory::after{
    content: '*';
    color: red;
    margin-left: auto;
}
.contactpage-content label.error,
.contactpage-question label.error{
    color:red;
}
.contactpage-content label.error::before,
.contactpage-question label.error::before{
    content:'x';
    margin-right: 10px;
}
.contactpage-content .contactpage-question{
    width: 100%;
}
.contactpage-content .contactpage-question textarea{
    width: 100%;
    height: 140px;
    text-wrap: wrap;
    text-align: start;
    overflow: auto;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    border:1px solid rgb(220,221,222);
}
.contactpage-content .contactpage-submit{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactpage-content .contactpage-submit button{
    padding-left: 35px;
    padding-right: 35px;
    height: 40px;
    background-color: rgb(129,194,68);
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}
.contactpage-submit{
    box-sizing: border-box;
    padding-top: 30px;
}
.contactpage-content .contactpage-submit button:hover{
    background-color: rgb(108,136,46);
}
@media screen and (max-width: 800px) {
    .contactpage-content{
        padding-left: 10px;
        padding-right: 10px;
    }
    .contactpage-content .contactpage-submit button{
        width: 100%;
        font-size: 16px;
        height: 50px;
    }
}