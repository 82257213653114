.pageroute{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    text-wrap: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    /* margin-left: 30px; */
    margin-top: 10px;
    margin-bottom: 10px;
    /* background-color: black */
}
.pageroute div{
    display: flex;
    justify-content: center;
    align-items: center;
}
.pageroute a:first-child{
    color:  rgba(129,194,68);
    font-size: 14px;
}
.pageroute a:first-child:hover{
    color: rgba(129,194,68,0.5);
}
.pageroute a{
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    text-wrap: wrap;
    font-size: 12px;
}
.pageroute a:hover{
    color: rgb(129,194,68);
}
.pageroute p{
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    font-size: 12px;
}
.pageroute p:last-child{
    color: rgb(129,194,68);
}
@media screen and (max-width: 800px) {
    .pageroute{
        padding-left: 10px;
        padding-right: 10px;
    }
}