.bloglistpage{
    width: 100vw;
    display: flex;
    flex-direction: column  ;
    justify-content: center;
    align-items: center;
}
.bloglistpage-content{
    width: 100%;
    max-width: 1200px;
    padding-left:30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bloglistpage-content h1{
    width: 100%;
    text-align: left;
}
.bloglistpage-content a{
    color:rgb(129,194,68);
    font-weight: bold;
}
.bloglistpage-content a:hover{
    color:rgb(108,136,46);
}
.bloglistpage-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
}
.bloglistpage-list-blog{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-top: 1px solid rgb(220,221,222);
    padding-top: 40px;
    box-sizing: border-box;
}
.bloglistpage-list .bloglistpage-list-blog:first-child{
    border:none;
}
.bloglistpage-list-blog img{
    max-width: 100%;
}
.bloglistpage-list-blog .bloglistpage-blog-title,.bloglistpage-list-blog .bloglistpage-blog-title:hover{
    color: black;
}
.bloglistpage-list-blog p {
    font-size: 14px;
    line-height: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    width: 100%;
    max-height: 120px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
}
.bloglistpage-list-blog-footer{
    width: 100%;
    display:flex;
    padding-top:10px;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
.bloglistpage-list-blog-footer a{
    width: fit-content;
    text-wrap: nowrap;
}
.bloglistpage-list-blog-footer p {
    width: 100%;
    text-align: right;
    font-size: 16px;
    color: rgb(90,90,90);
}
.bloglistpage-paging{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
}
.bloglistpage-paging button {
    background-color: white;
    min-width: 40px;
    height: 40px;
    border: none;
    margin-right: 5px;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}
.bloglistpage-paging button:hover{
    color:white;
    background-color: rgb(129,194,68);
}
@media screen and (max-width: 800px) {
    .bloglistpage-content{
        padding-left: 10px;
        padding-right: 10px;
    }
}