.checkoutpage{
    width: 100vw;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.checkoutpage-content{
    width:100%;
    min-width: fit-content;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.checkoutpage-navi{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(220,221,222);
    padding-bottom: 20px;
    gap: 20px;
}
.checkoutpage-navi button{
    background-color: white;
    border: none;
    font-size: 20px;
    color: rgb(220,221,222);
}
.checkoutpage-navi button.active{
    color:black;
}
.checkoutpage-navi button:hover{
    cursor: auto;    
}
.checkoutpage-order{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap:30px
}
.checkoutpage-order-info{
    width: calc((100% - 30px) / 3 * 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 20px;
}
.checkoutpage-address,.checkoutpage-purchase-order{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid rgb(220,221,222);
    padding-bottom: 20px;
    box-sizing: border-box;
}
.checkoutpage-purchase-order{
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}
.checkoutpage-purchase-order h2{
    white-space: nowrap;
    box-sizing: border-box;
}
.checkoutpage-purchase-order input[type='text']{
    width: 100%;
    max-width: 200px;
    height: 40px;
    margin-left: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    border:1px solid rgb(220,221,222);
    border-radius: 5px;
}
.checkoutpage-purchase-order input[type='text'].error{
    border: 1px solid red;
}
.checkoutpage-purchase-order input[type='text']:focus{
    outline: 2px solid blue;
    outline-offset: 1px;
}
.checkoutpage-purchase-order input[type='checkbox']{
    margin-right: 10px;
}
.checkoutpage-purchase-order h2::before{
    content: '*';
    color:red;
    margin-right: 5px;
}
.checkoutpage-address h2,.checkoutpage-summary h2{
    width: 100%;
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid rgb(220,221,222);
}
.checkoutpage-address-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
.checkoutpage-address-content a{
    color: rgb(129,194,68);
    border-bottom: 1px solid rgb(129,194,68);
    margin-top: 10px;
}
.checkoutpage-address-content a:hover{
    color: rgb(108,136,46);
    border-bottom: 1px solid rgb(108,136,46);
}
.checkoutpage-address label{
    width: 100%;
    margin-bottom: 20px;
}
.checkoutpage-address label input{
    margin-right: 20px;
}
.checkoutpage-shipping-methods,
.checkoutpage-shipping-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
}
.checkoutpage-shipping-tag{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 20px;
    background-color: rgb(240,240,240);
}
.checkoutpage-shipping-tag input{
    margin-right: 20px;
}
.checkoutpage-shipping-info{
    width:calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
}
.checkoutpage-shipping-info p{
    margin: 0;
}
.checkoutpage-summary{
    width: calc((100% - 30px) / 3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.checkoutpage-summary-detail{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 5px;
    padding:20px;
    padding-bottom: 10px;
    box-sizing: border-box;
    border: 1px solid rgb(220,221,222);
}
.checkoutpage-summary-detail div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(220,221,222);
}
.checkoutpage-summary-detail div:last-child{
    margin-top: 15px;
    border:none;
}

.checkoutpage-summary-detail div p{
    margin: 0;
    font-size: 14px;
}
.checkoutpage-summary-detail div p:last-child{
    margin-left: auto;
}
.checkoutpage-summary-detail div:last-child p{
    font-size: 20px;
    font-weight: bold;
}
.checkoutpage-summary-detail div:last-child p:last-child{
    color:rgb(129,194,68);
}
.checkoutpage-summary button{
    width: 100%;
    height: 40px;
    font-size: 16px;
    background-color: rgb(129,194,68);
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 20px;
}
.checkoutpage-summary button:hover{
    cursor: pointer;
    background-color: rgb(108,136,46);
}
.checkoutpage-summary button.disabled{
    background-color: rgb(220,221,222);
}
.checkoutpage-summary button.disabled:hover{
    background-color: rgb(220,221,222);
    cursor:auto;
}
@media screen and (max-width: 800px) {
    .checkoutpage-content{
        padding-left: 10px;
        padding-right: 10px;
    }
    .checkoutpage-order{
        flex-direction: column;
    }
    .checkoutpage-order-info{
        width: 100%;
    }
    .checkoutpage-summary{
        width: 100%;
    }
}