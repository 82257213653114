.productdetail{
    width: 100%;
    /* max-width: 865px;
    min-height: 270px; */
    display: flex;
    gap:20px;
    margin-bottom: 20px;
}
.productdetail-display{
    width: calc((100% - 20px)/3);
    max-width: 270px;
    /* min-height: 270px; */
}
.productdetail-display img{
    max-width: 100%;
    max-height: 100%;
}
.productdetail-tag-group{
    padding: 0px;
    margin: 0px;
    position: absolute;
    gap: 10px;
    display: flex;
}
.productdetail-tag{
    z-index: 888;
    background-color: rgb(187,84,81);
    margin-top: 0;
    margin-bottom: 0;
    height: 30px;
    width: 50px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
}
.productdetail-information{
    margin: 0;
    width: calc((100% - 20px) / 3 * 2);
    max-width: 595px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap:10px;
}
.productdetail-information h3{
    margin-bottom: 0px;
}
.productdetail-information a{
    width: 100%;
    /* min-height: 65px; */
    color: black;
    font-size: 20px;
    font-weight: bold;
}
.productdetail-price{
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    width:100%;
    max-height: 20px;
    /* background-color: black; */
}
.productdetail-price p{
    margin-bottom: 0px;
}
.productdetail-price p:first-child{
    font-size: 16px;
    color: rgb(165,165,165);
    margin-right: 4px;
}
.productdetail-price p.origin-price{
    font-size: 20px;
    color: rgb(165,165,165);
    margin-right: 8px;
    font-weight: bold;
    text-decoration: line-through;
}
.productdetail-price p.sell-price{
    font-size: 20px;
    color: rgb(143,192,86);
    font-weight: bold;
}
.productdetail-information p{
    margin-top: 0px;
    font-family: Nunito,sans-serif;
}
.productdetail-description{
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    width: 100%;
    height: 100%;
    max-height: 120px;
    overflow: hidden;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    line-height: 25px;
    font-size: 14px;
    box-sizing: border-box;
    padding-bottom: 10px;
    /* background-color: green; */
}
.productdetail-content{
    white-space: normal;
    text-overflow: ellipsis;
}
.productdetail-information button{
    margin-top: auto;
    box-sizing: border-box;
    width: 180px;
    min-height: 35px;
    border-radius: 4px;
    border: none;
    background-color: rgb(142,191,85);
    color:white;
    font-size: 14px;
    font-weight: bold;
}
.productdetail-information button.disabled{
    background-color: rgb(190,190,190);
}
.productdetail-information button.disabled:hover{
    cursor: auto;
    background-color: rgb(190,190,190);
}
.productdetail-information button:hover{
    background-color: rgb(177,211,136);
}

@media screen and (max-width: 800px) {
    .productdetail{
        min-height: 220px;
    }
    .productdetail-information a{
        font-size: 18px;
        font-weight: normal;
    }
    .productdetail-display{
        /* min-width: 220px; */
        min-height: 220px;
    }
    .productdetail-description{
        font-size: 14px;
    }
}