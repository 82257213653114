.accountpage{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.accountpage-content{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.accountpage h1{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    text-align: left;
}
.accountpage-navi{
    width: 100%;
    border:1px solid rgb(220,221,222);
    border-radius: 5px;
}
.accountpage-navi table{
    width: 100%;
    height: 45px;
    /* border: 1px solid rgb(220,221,222); */
    border-collapse: collapse;
}
.accountpage-navi table tr{
    width: fit-content;
}
.accountpage-navi table td{
    border-right: 1px solid rgb(220,221,222);;
    width: fit-content;
    box-sizing: border-box;
}
.accountpage-navi button{
    text-wrap: nowrap;
    background-color: white;
    border: none;
    padding: 10px;
    color:black;
}
.accountpage-navi button:hover{
    cursor:text;
}
.accountpage-navi button.active{
    text-wrap: nowrap;
    background-color: white;
    border: none;
    padding: 10px;
    color:rgb(129,194,68);
}
.accountpage-navi button.active:hover{
    color: rgb(108,136,46);
    cursor: pointer;
}
.accountpage-navi table td:last-child{
    border:none;
    width: 100%;
}
.accountpage-orderlist{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.accountpage-orderlist table{
    width: 100%;
    border-collapse: collapse;
}
.accountpage-orderlist table tr:first-child{
    height: 40px;
    border-bottom:1px solid rgb(220,221,222);
}
.accountpage-orderlist table tr{
    height: 60px;
    border-bottom:1px solid rgb(220,221,222);
}
.accountpage-orderlist table tr td{
    text-align: center;
}
.accountpage-orderlist table tr td:first-child{
    text-align: left;
}
.accountpage-orderlist a{
    color: rgb(129,194,68);
    text-decoration: underline;
}
.accountpage-orderlist a:hover{
    color:rgb(108,136,46);
}
.accountpage-addresseslist{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap:20px;
}
.accountpage-add-addres{
    width:calc((100% - 40px) / 3 );
    flex: 0 0 auto;
    min-width: fit-content;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid rgb(220,221,222);
    font-size: 14px;
    box-sizing: border-box;
    padding:20px;
    padding-top: 0;
    font-weight: bold;
}
.accountpage-add-addres:hover{
    color:  rgb(108,136,46);
}
.accountpage-addressesform,
.accountpage-accountsetting{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.accountpage-addressesform form,
.accountpage-accountsetting form{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
.accountpage-addressesform label.mandatory::after,
.accountpage-accountsetting label.mandatory::after{
    content: '*';
    color: red;
    margin-left: auto;
}

.accountpage-addressesform label,
.accountpage-accountsetting label{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 0;
}
.accountpage-addressesform label.error,
.accountpage-accountsetting label.error{
    color:red;
}
.accountpage-addressesform label.error::before,
.accountpage-accountsetting label.error::before{
    content:'x';
    margin-right: 10px;
}
.accountpage-addressesform div,
.accountpage-accountsetting form div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin: 0;
    padding: 0;
}
.accountpage-addressesform p,
.accountpage-accountsetting p{
    width: calc((100% - 20px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
}
.accountpage-addressesform p input,
.accountpage-accountsetting p input{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid rgb(220,221,222);
    box-sizing: border-box;
    padding-left: 20px;
}
.accountpage-addressesform p input.error,
.accountpage-addressesform p input.error
.accountpage-accountsetting p input.error{
    border: 1px solid red;
}
.accountpage-addressesform p input:focus,
.accountpage-accountsetting p input:focus{
    outline: 2px solid blue;
    outline-offset: 1px;
}
.accountpage-addressesform p select{
    border: 1px solid rgb(220,221,222);
    border-radius: 4px;
    padding-left: 20px;
    display: flex;
    width: 100%;
    height: 40px;
    text-align: left;
    margin-left: auto;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}
.accountpage-addressesform .accountpage-addressesform-button,
.accountpage-accountsetting .accountpage-accountsetting-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.accountpage-addressesform .accountpage-addressesform-button button,
.accountpage-accountsetting .accountpage-accountsetting-button button{
    padding-left: 35px;
    padding-right: 35px;
    height: 40px;
    background-color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
}
.accountpage-addressesform .accountpage-addressesform-button button:hover,
.accountpage-accountsetting .accountpage-accountsetting-button button:hover{
    background-color: rgb(240,240,240);
    color: rgb(108,136,46);
}
.accountpage-addressesform .accountpage-addressesform-button button:first-child,
.accountpage-accountsetting .accountpage-accountsetting-button button:first-child{
    background-color: rgb(129,194,68);
    color: white;
}
.accountpage-addressesform .accountpage-addressesform-button button:first-child:hover,
.accountpage-accountsetting .accountpage-accountsetting-button button:first-child:hover{
    background-color: rgb(108,136,46);
}
.accountpage-wishlists,.accountpage-messagelist{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.accountpage-empty{
    width: 100%;
    min-height: 35px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    background-color: rgb(182,242,236);
    margin-bottom: 20px;
    /* color:white */
}
.accountpage-wishlists table{
    width: 100%;
    border-collapse: collapse;
}
.accountpage-wishlists table tr:first-child{
    font-weight: bold;
}
.accountpage-wishlists table tr td:first-child{
    text-align: left;
}
.accountpage-wishlists table tr td{
    text-align: center;
}
.accountpage-wishlists table tr td{
    border-bottom: 1px solid rgb(240,240,240);
    padding-bottom: 10px;
    padding-top: 15px;
}
.accountpage-wishlists .accountpage-wishlists-list{
    border: none;
    padding:10px;
    background-color: white;
    color:rgb(129,194,68);
    font-size: 14px;
    text-decoration: underline;
}
.accountpage-wishlists .accountpage-wishlists-list:hover{
    color:rgb(108,136,46);
}
.accountpage-wishlists table tr td:last-child{
    text-align: right;
    
}
.accountpage-wishlists table tr td:last-child button{
    margin-left: 10px;
    height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
}
.accountpage-wishlists table tr td:last-child button:hover{
    background-color: rgb(240,240,240);
    color: rgb(108,136,46);
}
.accountpage-wishlists table tr td:last-child button:first-child{
    background-color: rgb(129,194,68);
    color:white;
}
.accountpage-wishlists table tr td:last-child button:first-child:hover{
    background-color: rgb(108,136,46);
}
.accountpage-wishlists-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
    margin-top: 30px;
}

.accountpage-wishlists-button button{
    padding-left: 35px;
    padding-right: 35px;
    height: 40px;
    background-color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
}
.accountpage-wishlists-button button:hover{
    background-color: rgb(240,240,240);
    color: rgb(108,136,46);
}
.accountpage-wishlists-button button:first-child{
    background-color: rgb(129,194,68);
    color: white;
}
.accountpage-wishlists-button button:first-child:hover{
    background-color: rgb(108,136,46);
}
.accountpage-wishcreate{
    width: 100%;
    max-width:530px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}
.accountpage-wishcreate form{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.accountpage-wishcreate p{
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding:0;
}
.accountpage-wishcreate p label{
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 10px;
}
.accountpage-wishcreate p label.error{
    color: red;
}
.accountpage-wishcreate p label.error::before{
    content: "x";
    color: red;
    margin-right: 10px;
}
.accountpage-wishcreate p input[type='text']{
    width: 100%;
    height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    border:1px solid rgb(220,221,222);
    border-radius: 5px;
}
.accountpage-wishcreate p input[type='text'].error{
    border: 1px solid red;
}
.accountpage-wishcreate p input[type='text']:focus{
    outline: 2px solid blue;
    outline-offset: 1px;
}
.accountpage-wishcreate p input[type='checkbox']{
    margin-right: 10px;
}
.accountpage-wishcreate-button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.accountpage-wishcreate-button button{
    padding-left: 35px;
    padding-right: 35px;
    height: 40px;
    background-color: rgb(129,194,68);
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
}
.accountpage-wishcreate-button button:hover{
    background-color: rgb(108,136,46);
}
.accountpage-wishitems,.accountpage-recentlist{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center ;
}
.accountpage-items-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.accountpage-export div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.accountpage-export div a{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    width: 200px;
    border-radius: 5px;
    background-color: rgb(129,194,68);

}
.accountpage-export div a:hover{
    background-color: rgb(108,136,46);
}
@media screen and (max-width: 800px) {
    .accountpage h1{
        padding-left: 10px;
        padding-right: 10px;
    }
    .accountpage-content{
        padding-left: 10px;
        padding-right: 10px;
    }
    .accountpage-add-addres{
        width: calc((100% - 20px) / 2);
    }
    .accountpage-navi{
        display: none;
    }
}