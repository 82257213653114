.account-address{
    width:calc((100% - 40px) / 3 );
    /* flex: 0 0 auto; */
    /* min-width: fit-content; */
    max-height: 250px;
    display: flex;
    align-items: flex-start;
    background-color: rgb(240,240,240);
    font-size: 14px;
    box-sizing: border-box;
    padding:20px;
    padding-top: 0;
}
.account-address input[type='checkbox']{
    width: 20px;
    margin-top: 20px;
    margin-right: 20px;
}
.account-address-content{
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.account-address p{
    max-width: 100%;
    margin: 0;
    overflow: hidden;
    text-wrap: wrap;
    line-height: 20px;
}
.account-address-phone{
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}
.account-address-phone p:first-child{
    color: rgb(90,90,90);
}

.account-address-button{
    margin-top: auto;
}
.account-address-button button{
    background-color: white;
    border: none;
    padding-left: 25px;
    padding-right: 25px;
    height: 30px;
    color: black;
    font-weight: bold;
    border-radius: 5px;
    margin-right: 10px;
}
.account-address-button button:hover{
    background-color: rgb(240,240,240);
    color:rgb(108,136,46);
}
.account-address-button button:first-child{
    background-color: rgb(128,195,66);
    color: white;
}
.account-address-button button:first-child:hover{
    background-color: rgb(108,136,46);
}
@media screen and (max-width: 800px) {
    .account-address{
        width: calc((100% - 20px) / 2);
    }
} 