.termofsale{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.termofsale-content{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.termofsale-content p,.termofsale-content li{
    font-size: 14px;
    line-height: 20px;
}
.termofsale-content h4{
    font-size: 14px;
}
.termofsale-content ul,.termofsale-content li{
    list-style: disc;
}
.termofsale-content a{
    color:rgb(129,194,68);
    text-decoration: underline;
}
.termofsale-content a:hover{
    color:rgb(108,136,46);
}
@media screen and (max-width:800px) {
    .termofsale-content{
        padding-left: 10px;
        padding-right: 10px;
    }
}