.productbrief{
    /* min-width: 210px;
    min-height: 480px; */
    flex:0 0 auto;
    display: flex;
    flex-direction: column;
}
.productbrief-display{
    width: 100%;
    /* height: 200px; */
    /* max-height: 270px; */
    /* background-color: blue; */
    display: flex;
}
.productbrief-display img{
    /* height: 200px; */
    max-height: 200px;
    max-width: 100%;
    /* max-height: 270px; */
}
.productbrief-tag-group{
    padding: 0px;
    margin: 0px;
    position: absolute;
    gap: 10px;
    display: flex;
}
.productbrief-display a{
    align-self: center;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.productbrief-tag{
    z-index: 888;
    background-color: rgb(187,84,81);
    margin-top: 0;
    margin-bottom: 0;
    min-height: 30px;
    width: 50px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
}
.productbrief-title{
    width: 100%;
    min-height: fit-content;
    overflow: hidden;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: green; */
    /* margin-top: 205px; */
}
.productbrief-title a{
    display: flex;
    justify-content: center;
    text-align: center;
    color: black;
}
.productbrief-title a:hover{
    color: rgba(0,0,0,0.7);
}
.productbrief-footer{
    margin-top: auto;
    display: flex;
    flex-direction: column;
}
.productbrief-price{
    min-height: 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* background-color: black; */
    margin-top: 0;
    padding-top: 0;
}
.productbrief-price p{
    margin-top: 0;
    margin-bottom: 0;
}
.productbrief-price p:first-child{
    color: rgb(165,165,165);
    font-size: 14px;
    margin-right: 2px;
}
.productbrief-price p.origin-price{
    color: rgb(165,165,165);
    font-weight: bold;
    font-size: 18px;
    text-decoration: line-through;
    margin-right: 4px;
}
.productbrief-price p.sell-price{
    color: rgb(143,192,86);
    font-weight: bold;
    font-size: 18px;
}
.productbrief-button,.productbrief-remove{
    width: 100%;
    height: 35px;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}
.productbrief-button button{
    background-color: rgb(143,192,86);
    width: 100%;
    height: 100%;
    max-height: 35px;
    color: white;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    border-radius: 4px;
}
.productbrief-button button.disabled{
    background-color: rgb(190,190,190);
}
.productbrief-button button.disabled:hover{
    cursor: auto;
    background-color: rgb(190,190,190);
}

.productbrief-button button:hover{
    background-color: rgb(177,211,136);
}
.productbrief-remove button{
    background-color: white;
    width: 100%;
    height: 100%;
    max-height: 35px;
    color: black;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    border-radius: 4px;
}
.productbrief-remove button:hover{
    background-color: rgb(240,240,240);
    color: rgb(177,211,136);
}
@media screen and (max-width: 800px) {
    .productbrief-button button{
        width: 100%;
    }
    .productbrief-remove button{
        width: 100%;
    }
}