.orderpage{
    width:100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.orderpage-content{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.orderpage-placing{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.orderpage-title{
    width: 100%;
    display: flex;
    justify-items: flex-start;
    align-items: center;
    border-bottom: 1px solid rgb(220,221,222);
}
.orderpage-title p{
    margin-left: auto;
    font-size: 16px;
}
.orderpage-summary{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    gap:30px;
}
.orderpage-summary-customerinfo{
    width: calc((100% - 30px) / 3 * 2);
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}
.orderpage-summary-customerinfo div{
    width: calc((100% - 10px) / 2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.orderpage-summary-customerinfo div p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
}
.orderpage-summary-customerinfo div h4{
    margin-top: 20px;
    margin-bottom: 10px;
}
.orderpage-summary-price{
    width: calc((100% - 30px) / 3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 20px;
    padding:20px;
    box-sizing: border-box;
    background-color: rgb(240,240,240);
}
.orderpage-summary-price h4{
    margin-top: 0;
}
.orderpage-summary-price div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 15px;
}
.orderpage-summary-price div p{
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: rgb(150,150,150);
}
.orderpage-summary-price div p:last-child{
    margin-left: auto;
    font-size: 14px;
    font-weight: normal;
    color: black;
}
.orderpage-summary-price div:last-child{
    border-top: 1px solid rgb(150,150,150);
    padding-top: 15px;
}
.orderpage-shipping{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    align-items: center;
}
.orderpage-shipping div{
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.orderpage-shipping div h2{
    margin: 0;
}
.orderpage-shipping div p{
    margin: 0;
}
.orderpage-shipping button{
    margin-left: auto;
    padding-left: 30px;
    padding-right: 30px;
    height: 40px;
    background-color: rgb(129,194,68);
    border: none;
    font-size: 16px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
}
.orderpage-shipping button:hover{
    background-color: rgb(108,136,46);;
}
.orderpage-itemlist{
    width: 100%;
    margin-top: 20px;
}
.orderpage-itemlist table{
    width: 100%;
    border-collapse: collapse;
}
.orderpage-itemlist table tbody{
    width: 100%;
}

.orderpage-itemlist table tbody tr:first-child{
    width: 100%;
    border-bottom: 1px solid rgb(220,221,222);
    font-weight: bold;
}
.orderpage-itemlist table tbody tr td:first-child{
    width: 50%;
    text-align: left;
}
.orderpage-itemlist table tbody tr td:last-child{
    text-align: right;
}
.orderpage-itemlist table tbody tr td{
    padding: 10px;
    text-align: center;
}
.oderpage-itemlist-item{
    width: 100%;
}
.oderpage-itemlist-item img{
    max-width: 100px;
    max-height: 100%;
}

.oderpage-itemlist-item p {
    margin: 0;
}
.orderpage-itemlist table tbody tr td div{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    gap:15px;
}