a{
  text-decoration: none;
}
a:link,a:visited,a:hover,a:active,a:focus{
  text-decoration: none;
  color:white;
}

.App{
  font-family:Nunito,Arial,Helvetica,sans-serif;
  width: 100dvw;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
ul,li{
  list-style: none;
}
h1, h2, h3, h4, h5, h6, a {
  font-family:Nunito,Arial,Helvetica,sans-serif;
}
.App button{
  /* font-family:Consolas; */
}
.App button:hover{
  cursor: pointer;
}
@import url('https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900');

@font-face {
  font-family: "Roboto";
  src: url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
}
@font-face {
  font-family: "Consolas";
  src: url("https://db.onlinewebfonts.com/t/1db29588408eadbd4406aae9238555eb.eot");
  src: url("https://db.onlinewebfonts.com/t/1db29588408eadbd4406aae9238555eb.eot?#iefix")format("embedded-opentype"),
  url("https://db.onlinewebfonts.com/t/1db29588408eadbd4406aae9238555eb.woff2")format("woff2"),
  url("https://db.onlinewebfonts.com/t/1db29588408eadbd4406aae9238555eb.woff")format("woff"),
  url("https://db.onlinewebfonts.com/t/1db29588408eadbd4406aae9238555eb.ttf")format("truetype"),
  url("https://db.onlinewebfonts.com/t/1db29588408eadbd4406aae9238555eb.svg#Consolas")format("svg");
}