.signin{
    width: 100vw;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signin-content{
    width: 100%;
    max-width: 1000px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.signin-error{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(240,213,213);
    border-radius: 5px;
}
.signin-error p{
    font-size: 14px;
    color: rgb(90,90,90);
    margin-right: 20px;
}
.signin-error p:first-child{
    margin:20px;
    font-size: 30px;
    color:rgb(239,132,129);
}
.signin-main{
    width: 100%;
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    /* gap:30px; */
}
.signin-input{
    /* width: calc((100% - 30px) / 2);  for having register*/
    width: 100%;
    max-width: 500px;
    height: fit-content!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding-bottom: 20px;
}
.signin-input p{
    height: 20px;
    margin: 0;
    margin-bottom: 5px;
}
.signin-input input{
    margin-bottom: 5px;
    border: solid 1px rgb(220,221,222);
    border-radius: 5px;
    background-color: transparent;
    height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    width: 100%;
    font-size:16px;
}
.signin-input-button{
    width: 100%;
    margin-top: auto;
}
.signin-input input:focus, .signin-forget div input:focus,.signin-reset input:focus{
    outline: 2px solid blue;
    outline-offset: 1px;
}
.signin-input button{
    height: 40px;
    padding-left: 35px;
    padding-right: 35px;
    border: none;
    border-radius: 5px;
    background-color: rgb(129,194,68);
    margin-right: 20px;
    color: white;
    font-weight: bold;
}
.signin-input button:hover,.signin-forget div button:hover{
    background-color: rgb(108,136,46);
}
.signin-input .signin-forget-button{
    color: rgb(129,194,68);
    background-color: white;
    text-decoration: underline;
}
.signin-input .signin-forget-button:hover{
    color: rgb(108,136,46);
    background-color: white;
}
.signin-new{
    width: calc((100% - 30px) / 2);
    height: fit-content;
    background-color: rgb(242,242,242);
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
}

.signin-new ul{
    margin: 0;
    padding: 0;
    font-size: 14px;
    margin-bottom: 20px;
}
.signin-new ul li:first-child{
    margin-top: 10px;
}

.signin-new ul li{
    height: 20px;
    margin-left: 30px;
    padding-left: 10px;
    list-style:disc;
    font-size: 14px;
}
.signin-new button{
    height: 40px;
    padding-left: 35px;
    padding-right: 35px;
    border: none;
    border-radius: 5px;
    background-color: rgb(129,194,68);
    margin-right: 20px;
    color: white;
    font-weight: bold;
}
.signin-new button:hover{
    background-color: rgb(108,136,46);
}
.signin-forget{
    width: 100%;
    max-width: 1000px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.signin-reset{
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.signin-forget div{
    width: 100%;
    display: flex;
    gap:20px;
}
.signin-forget div input{
    width: calc((100% - 20px) / 3 * 2);
    height: 40px;
    border: solid 1px rgb(220,221,222);
    border-radius: 5px;
    background-color: transparent;
    height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 16px;

}
.signin-reset input{
    width: 100%;
    height: 40px;
    border: solid 1px rgb(220,221,222);
    border-radius: 5px;
    background-color: transparent;
    height: 40px;
    padding-left: 20px;
    box-sizing: border-box;
    font-size: 16px;
}
.signin-forget div button{
    width: calc((100% - 20px) / 3);
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: rgb(129,194,68);
    color: white;
    font-weight: bold;
    font-size: 16px;
}
.signin-reset button{
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: rgb(129,194,68);
    color: white;
    font-weight: bold;
    font-size: 16px;
}
.signin-reset button{
    margin-top: 20px;
}
.sigin-rest-username{
    display: flex;
    align-items: center;
    border: solid 1px rgb(220,221,222);
    border-radius: 5px;
    width: 100%;
}
.sigin-rest-username input{
    border: none;
    border-radius: 5px 0 0 5px;
}
.sigin-rest-username button{
    margin: 0;
    max-width: 200px;
    border-radius: 0 5px 5px 0;
}
.sigin-rest-username input.disabled{
    background-color: rgb(220,221,222);
}
.sigin-rest-username button.disabled{
    background-color: rgb(190,190,190);
}
.sigin-rest-username button.disabled:hover{
    cursor:auto;
}
.signin-password-div{
    display: flex;
    align-items: center;
    border: solid 1px rgb(220,221,222);
    border-radius: 5px;
    width: 100%;
}
.signin-password-div button{
    margin:0;
    border-radius: 0 5px 5px 0;
    background-color: white;
    color: rgb(190,190,190);
    max-width: 100px;
}
.signin-password-div button:hover{
    background-color: white;
    color: rgb(120,120,120);
}
.signin-password-div input{
    margin: 0;
    border-radius: 5px 0 0 5px;
    border: none;
}
.signin-password-div input:focus,.sigin-rest-username input:focus{
    outline: none;
    outline-offset: 0;
}
.signin-password-div:focus-within,.sigin-rest-username:focus-within{
    outline: 2px solid blue;
    outline-offset: 1px;
}
@media screen and (max-width: 800px) {
    .signin-content,.signin-reset,.signin-forget{
        padding-right: 10px;
        padding-left: 10px;
    }
    .signin-main{
        flex-direction: column;
        gap:0px;
    }
    .signin-input{
        width: 100%;
        padding-left: 10px;
    }
    .signin-new{
        width: calc(100% - 10px);
        margin-left: 10px;

    }

}