.header-user{
    width: calc((100% - 100px) / 4);
    max-width: 250px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}
.header-user button{
    display: flex;
    max-width: 100px;
    font-size: 16px;
    margin-top: 0;
    font-family: Arial, Helvetica, sans-serif;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: none;
    background-color: white;
    color: black;
}
.header-user button p{
    margin-top: 5px;
}
/* .header-user a:first-child{
    margin-left: 500px;
} */
.header-user button:nth-child(2){
    margin-bottom: 0px;
}
@media screen and (max-width: 800px) {
    .header-user{
        display: none;
    }
}