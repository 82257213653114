.legalnotice{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.legalnotice-content{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.legalnotice-content p{
    font-size: 14px;
    line-height: 20px;
}
.legalnotice-content h4{
    font-size: 16px;
    margin-bottom: 0px;
}
@media screen and (max-width:800px) {
    .legalnotice-content{
        padding-left: 10px;
        padding-right: 10px;
    }
}