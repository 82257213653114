.multiselect-main{
    /* width: 30%;
    max-width: 350px; */
    padding-right: 20px;
    box-sizing: border-box;
    border-right: 1px solid rgb(220,221,222);
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    margin-right: 20px;
}

.multiselect-mobile-title{
    display: none;
}
.multiselect{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    /* padding-right: 40px; */
    border: 2px;
    border-radius: 5px;
    border-color: black;
    /* background-color: green; */
    /* border-style: dashed; */
    /* padding-left: 20px; */
    /* background-color: yellow; */
}
.multiselect-content{
    width: 100%;
    margin-bottom: 20px;
}
.multiselect button{
    width: 100%;
    display: flex;
    text-align: left;
    border: 0px;
    border-bottom: 1px;
    border-style: solid;
    border-color: rgb(220,221,222);
    background-color: white;
    margin-bottom: 20px;
    padding-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 0;
}
.multiselect button.show::after{
    content:'\25B2';
    margin-left: auto;
    color: rgb(190,190,190);
}
.multiselect button.hide::after{
    content:'\25BC';
    margin-left: auto;
    color: rgb(190,190,190);
}
.multiselect-shopby{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(220,221,222);
    margin-bottom: 10px;
}
.multiselect-shopby h1{
    width: fit-content;
    text-align: left;
    padding: 0;
    font-size: 20px;
}
.multiselect-shopby p{
    margin: 0;
    font-size: 14px;

}
.multiselect-ul{
    /* background-color: red; */
    margin:0px;
    padding: 0px;
}
.multiselect-div{
    display: block;
    position: relative;
    padding-left: 35px;
    margin-top: 15px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* background-color: green; */
}
.multiselect-div label{
    font-size: 16px;
}
.multiselect-div label:hover{
    cursor: pointer;
    color: rgb(129,194,68);
    text-decoration: underline;
}
.multiselect-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 19px;
    width: 19px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid rgb(220,221,222);
}
.multiselect-input:checked ~ .checkmark {
    background-color: rgb(129,194,68);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.multiselect-input:checked ~ .checkmark:after {
    display: block;
}
.multiselect-div .checkmark:after {
    left: 5px;
    top: 0px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 800px){
    .multiselect-mobile-title{
        max-width: 100%;
    }
    .multiselect-mobile-title h1{
        font-size: 20px;
    }
    .multiselect-main{
        width: 40vw;
        height: 100vh;
        background-color: white;
        top:0;
        left: 0;
        z-index: 999;
        position: absolute;
        padding-left: 10px;
        padding-right: 10px;
        overflow: auto;
    }
    .multiselect-mobile-title{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
    }
    .multiselect-mobile-title h1{
        width: fit-content;
        text-wrap: nowrap;
        font-size: 24px;
        text-align: left;
        margin: 0;
        box-sizing: border-box;
        padding-left: 0px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .multiselect-mobile-title button{
        height: 100%;
        font-size: 30px;
        padding-top: 10px;
        margin-left: auto;
        border: none;
        font-weight: bold;
        color: rgb(190,190,190);
        background-color: white;
        box-sizing: border-box;
    }
    .multiselect-search{
        width: calc(100% - 20px);
        padding: 10px;
        border: 1px solid rgb(220,221,222);
        border-radius: 4px;
        margin-bottom: 10px;
    }
    .multiselect-mobile-filter{
        width: 100%;
        background-color: rgb(129,194,68);
        padding-top: 10px;
        padding-bottom: 10px;
        color: white;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}
@media screen and (min-width: 801px){
    .multiselect-search{
        border:1px solid rgb(220,221,222);
        border-radius: 5px;
        width: 100%;
        height: 35px;
        box-sizing: border-box;
        margin-bottom: 20px;
        text-align: left;
        padding-left: 10px;
        padding-right: 10px;
    }
    .multiselect-mobile-filter{
        display: none;
    }
}
@media screen and (max-width: 550px){
    .multiselect-mobile-title{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        /* background-color: blue; */
    }
    .multiselect-content button{
        font-size: 14px;
    }
    .multiselect-div label{
        font-size: 14px;
    }
    .multiselect-mobile-title h1{
        font-size: 20px;
        height: 100%;
        /* background-color: green; */
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }
}