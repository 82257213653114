.itemadd{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top:0;
    z-index: 999;
    background-color: rgb(56,56,56);
    display: flex;
    justify-content: center;
    align-items: center;
}
.itemadd-content{
    width: 100%;
    max-width: 1200px;
    min-height: 400px;
    margin-right: 30px;
    margin-left: 30px;
    padding:0 30px 30px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    gap: 20px;
}
.itemadd-title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.itemadd-title button{
    margin-left: auto;
    background-color: white;
    border: none;
    font-size: 40px;
    text-align: right;
    color:rgb(190, 190, 190)
}
.itemadd-title button:hover{
    color:  black;
}
.itemadd-main{
    width: 100%;
    gap: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.itemadd-content-main{
    width: calc((100% - 20px) / 4 * 3);
    display: flex;
    flex-direction: column;
}
.itemadd-content-main-item{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    gap: 10px;
}
.itemadd-content-main-item img{
    max-width: calc((100% - 10px) / 4);
    max-height: 100%;
}
.itemadd-content-main-item-info{
    width: calc((100% - 10px) / 4 * 3);
}
.itemadd-content-main-item-info h2{
    font-size: 22px;
}
.itemadd-content-main-item-info p {
    font-size: 20px;
}
.itemadd-content-main-checkout{
    width: calc((100% - 20px) / 4);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.itemadd-content-main-checkout button{
    width: 100%;
    min-height: 40px;
    border-radius: 5px;
    border:none;
    font-size: 14px;
    font-weight: bold;
    color: white;
}
.itemadd-content-main-checkout button:first-child{
    font-size: 16px;
    background-color: rgb(129,194,68);
    outline: 1px solid red;
    margin-bottom: 10px;
}
.itemadd-content-main-checkout button:first-child:hover{
    background-color: white;
    color: rgb(129,194,68);
}
.itemadd-content-main-checkout button:nth-child(5){
    margin-top: 10px;
    background-color: black;
}
.itemadd-content-main-checkout button:nth-child(5):hover{
    background-color: rgba(0, 0, 0, 0.6);
}
.itemadd-content-main-checkout p,.itemadd-content-main-checkout h1{
    margin: 0px;
}
.itemadd-content-main-checkout p:nth-child(2){
    width: 100%;
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid rgb(220,221,222);
}
.itemadd-content-main-checkout p:last-child{
    margin-top: 10px;
    text-decoration: underline;
    color: rgb(129,194,68);;
}
.itemadd-content-main-checkout p:last-child:hover{
    color: rgb(108,136,46);
}
@media screen and (max-width: 800px) {
    .itemadd-content{
        height: calc(100vh - 20px);
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 10px;
        padding-right: 10px;
        overflow: auto;
    }
    .itemadd-main{
        flex-direction: column;
    }
    .itemadd-title h1{
        font-size: 24px;
    }
    .itemadd-content-main{
        width: 100%;
    }
    .itemadd-content-main-item{
        flex-direction: column;
    }
    .itemadd-content-main-item img{
        max-width: 100%;
    }
    .itemadd-content-main-item-info{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .itemadd-content-main-item-info h2{
        font-weight: normal;    
        text-align: center;
    }
    .itemadd-content-main-checkout{
        width: 100%;
    }
    .itemadd-content-main-checkout button{
        height: 60px;
    }
    .itemadd-content-main-checkout p:last-child{
        font-weight: bold;
        font-size: 18px;
    }
    .itemadd-content-main-checkout button:nth-child(5){
        font-size: 16px;
    }

}
@media screen and (min-width: 801px) {
    
}