.blogpage{
    width: 100vw;
    display: flex;
    flex-direction: column  ;
    justify-content: center;
    align-items: center;
}
.blogpage-content{
    width: 100%;
    max-width: 1200px;
    padding-left:30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.blogpage-content p{
    font-size: 14px;
}
.blog-imag-para{
    float: left;
    max-width: 120px;
    margin-right: 10px;
}
.blog-imag-para-none{
    display: none;
}
.blogpage-title{
    width: 100%;
    max-width: 1200px;
}
.blogpage-image-main{
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
.blogpage-image-main img{
    width: 100%;
    max-width: 1200px;
}
.blogpage-subcontent p a{
    color:rgb(129,194,68);
    text-decoration: underline;
}
.blogpage-subcontent p a:hover{
    color: rgb(108,136,46);
}
.blogpage-content-footer{
    width: 1200px;
    margin-left: 30px;
    display: flex;
    justify-content: right;
    align-items: center;
    border-bottom: 1px solid rgb(220,221,222);
}
.blogpage-content-footer p {
    color:rgb(122,122,122);
    margin-right: 10px;
}
@media screen and (max-width: 800px) {
    .blogpage-content{
        padding-left: 10px;
        padding-right: 10px;
    }
}