
.logo img{
    width: 100%;
    max-width:250px;
}
.logo{
    display: flex;
    max-width: 250px;
    /* background-color: green; */
    /* flex-direction: row-reverse; */
}
.logo button{
    text-decoration: none;
    background-color: transparent;
    border:none;
}
@media screen and (max-width: 800px) {
    .logo{
        max-width:150px;
        max-height: 60px;
    }
    .logo img{
        max-height: 60px;
    }
}

@media screen and (max-width: 550px){
    .logo{
        max-width:200px;
    }
    .logo img{
        max-width:200px;
    }
}