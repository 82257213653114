.blogbreif{
    padding: 20px 20px 20px 20px;
    width: calc((100% - 20px) / 2);
    max-width: 575px;
    min-height: 270px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    box-shadow: 2px 2px 5px 1px rgb(220,221,222);
}
.blogbreif-image{
    width: calc((100% - 10px) / 5 * 2);
    padding: 0;
    border: none;
    background-color: transparent;
    max-width: 220px;
    max-height: 270px;
}
.blogbreif-image img{
    width: 100%;
    max-width: 220px;
    max-height: 270px;
    border-radius: 6px;
}
.blogbreif-content{
    width: calc((100% - 10px) / 5 * 3);
}
.blogbreif-content-title{
    /* background-color: black; */
    color: black;
    font-size: 22px;
}
.blogbreif-content-title:hover{
    color: rgb(129,194,68)!important;
}
.blogbreif-content p{
    min-height: 70px;
    overflow: hidden;
    font-size: 13px;
}
.blogbreif-content-more{
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    color: rgb(129,194,68)
}
@media screen and (max-width: 800px) {
    .blogbreif{
        width: calc((100% - 20px));
    }
}