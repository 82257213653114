.exwarranty{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.exwarranty-content{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.exwarranty-content p,.exwarranty-content a,.exwarranty-content li{
    font-size: 14px;
    line-height: 20px;
}
.exwarranty-content a{
    font-size: 14px;
    color:rgb(129,194,68);
    text-decoration: underline;
}
.exwarranty-content a:hover{
    color:rgb(108,136,46);
}
.exwarranty-content img{
    max-width: 100%;
}
.exwarranty-content .exwarranty-main-links ul,
.exwarranty-content .exwarranty-main-links li,
.exwarranty-content .exwarranty-main-csr ul,
.exwarranty-content .exwarranty-main-csr li,
.exwarranty-terms-ul, .exwarranty-terms-ul li{
    list-style-type: disc;
}
.exwarranty-main-frequent,.exwarranty-main-frequent li{
    width: 100%;
}
.exwarranty-main-frequent li{
    display: flex;
    gap: 10px;
    margin-bottom: 5px;
}
.exwarranty-main-frequent li p{
    width: calc((100% - 10px) / 2);
    margin: 0;

}
.exwarranty-serial-cat,.exwarranty-serial-cat li{
    list-style: decimal;
}
.exwarranty-main,
.exwarranty-covered,
.exwarranty-fill-claim,
.exwarranty-serial,
.exwarranty-terms
{
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
@media screen and (max-width:800px) {
    .exwarranty-content{
        padding-left: 10px;
        padding-right: 10px;
    }
}