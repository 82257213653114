.recentposts-blog{
    width: 100%;
    max-width: 1200px;
    padding-right: 30px;
    padding-left: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.recentposts-blog-listing{
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px
}
@media screen and (max-width: 800px) {
    .recentposts-blog{
        padding-left: 10px;
        padding-right: 10px;
    }
    .recentposts-blog h1{
        font-size: 30px;
        font-weight: normal;
    }
    .recentposts-blog-listing{
        flex-direction: column;
    }

}