.productlisting{
    width: 100vw;
    margin-top: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.productlisting-main{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.productlisting-main h1{
    margin-bottom: 40px;
}
.productlisting-listing{
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
}
.productlisting-listing-main{
    width: calc(100% - 120px);
    display: flex;
    position: relative;
    overflow: hidden;
}
.productlisting-listing-box{
    width: 100%;
    gap:20px;
    display: flex;
    flex:0 0 auto;
    overflow: hidden;
}
.productlisting-listing-button{
    padding: 0;
    width: 30px;
    font-size: 35px;
    background-color: white;
    border: none;
}
@media screen and (max-width: 800px) {
    .productlisting-main{
        padding-left: 10px;
        padding-right: 10px;
    }
    .productlisting-main h1{
        font-size: 30px;
        font-weight: normal;
    }
    .productlisting-listing-box{
        gap:10px;
    }
}