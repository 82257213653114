.banner{
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}
.banner-main{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    max-height: 440px;
    overflow: hidden;
}
/* *{
    box-sizing: border-box;
} */
.wrapper{
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 440px;
    overflow: hidden;
    box-sizing: border-box;
}
.wrapper-box{
    width: 200%;
    height: 100%;
    max-height: 440px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    /* justify-content: center; */
    /* animation: wrapper 15s infinite; */
    margin-left:-100%;
}
.wrapper a{
    width: 100%;
}
.wrapper img{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    object-fit:contain;
    float: top;
}

.toggle{
    right: 0;
    top: 12px;
    display: flex;
    /* z-index: 999; */
}
.wrapper-footer .toggle button{
    margin-right: 12px;
    width: 28px;
    height: 28px;
    appearance: none;
    border: none;
    background-color: rgba(255,255,255,0.1);
    color: #fff;
    border-radius: 4px;
    cursor: pointer; 
}
.toggle-button{
    height: 70px;
    font-size: 30px;
    padding:0px;
    border: none;
    color: #fff;
    background-color: #fff;
}
.toggle-button:hover{
    color: black;
    cursor: pointer;
}
.wrapper-indicator{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    /* background-color: green; */
}
.wrapper-indicator li{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    cursor: pointer;
    background-color: black;
}
.wrapper-indicator button{
    background-color: transparent;
    border: none;
    /* background-color: blue; */
    margin: 0;
}
.wrapper-indicator li.active{
    width: 20px;
    height: 8px;
    border-radius: 4px;
    background-color: rgb(108,136,46);
}
@keyframes wrapper-scroll{
    0% {
        margin-left: 0px;
    }
    12.5%{
        margin-left: 0px;
    }
    25%{
        margin-left: -100%;
    }
    37.5%{
        margin-left: -100%;
    }
    50%{
        margin-left: -200%;
    }
    62.5%{
        margin-left: -200%;
    }
    75%{
        margin-left: -300%;
    }
    100%{
        margin-left: -300%;
    }
}

@media screen and (max-width: 800px) {
    .banner{
        flex-direction: column;
        padding-left:10px;
        padding-right: 10px;
        box-sizing: border-box;
    }
    .toggle{
        display: none;
    }
    .wrapper-indicator button{
        padding: 1px;
    }
    .wrapper-footer{
    }
    .wrapper-indicator li{
        width: 6px;
        height: 6px;
        margin: 4px;
        border-radius: 50%;
    }
    .wrapper-indicator li.active{
        width: 15px;
        height: 6px;
    }
}
@media screen and (min-width: 801px){
    .wrapper-footer{
        /* height: 44px; */
        /* top:624px; */
        /* background-color: rgb(100,67,68); */
        /* padding: 12px 12px 0 12px; */
        box-sizing: border-box;
        /* background-color: black; */
    }
    .wrapper-indicator button{
        padding: 5px;
    }
}