.coupon-footer{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
    background-color: rgb(60,60,60);
    border-top: 1px solid #E7E7E7;
}
.coupon-footer-content{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding-left: 30px; 
    padding-right: 30px;  
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-top-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F02727;
    color: white;
    height: 40px;
    width: 100vw;
    font-size: 16px;
}
.footer-main-content{
    width: 100%;
    display: flex;
    max-width: 1200px;
    color: white;
    margin-top: 60px;
    gap:30px;
    justify-content: left;
}
/* .footer-main-content div{
    flex-grow: 0;
    margin-right: 100px;
} */
/* .footer-main-content div:first-child{
    flex-grow: 0;
    margin-right: 30px;
} */
.footer-main-content li{
    list-style: none;
    margin-left: 0px;
    font-size:14px;
    margin-bottom: 10px;
}
.footer-main-content ul{
    padding-left: 0px;
}
.footer-main-content p{
    text-align: left;
}
.footer-category{
    box-sizing: border-box;
}
/* .footer-main-content div{
    margin-left: 20px;
} */

.footer-kbc-info{
    display: flex;
    height: fit-content;
    justify-content: left;
    flex-direction: column;
    width: fit-content;
}
.footer-kbc-info div:first-child{
    margin-left: 0px;
    height: fit-content;
}
.footer-kbc-info div:nth-child(2){
    width: 100%;
    margin-left: 0px;
    height: fit-content;
}
.footer-kbc-info div{
    font-size: 14px;
}
/* .footer-store-categories{
    display: flex;
} */
.footer-recent-articles li{
    font-size: 16px;
    list-style: none;
    color:#c3c3c3;
    width: 380px;
}
.footer-bottom-bar{
    width: 100vw;
    min-height: 90px;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: rgb(32,32,32);
}
.footer-bottom-content{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.footer-bottom-media{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-bottom-media div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right:10px;
    height:30px;
    width: 30px;    
    border-radius: 50%;
}

.footer-bottom-text{
    color: white;
    font-size: 14px;
}
.footer-category-navigate{
    padding-right:80px
}
@media screen and (max-width:800px) and (min-width:551px){

}
@media screen and (max-width:800px) {
    
    .coupon-footer-content{
        padding-left: 10px;
        padding-right: 10px;
    }
    .footer-bottom-content{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 10px;
        padding-right: 10px;
    }
    .footer-bottom-media{
        height: 100px;
    }
    .footer-bottom-mobile{
        width: 100%;
        border-top: 1px solid white;
    }
    .footer-bottom-web{
        display: none;
    }
    .footer-bottom-mobile{
        display: flex;
    }
}
@media screen and (min-width:801px) {
    .footer-bottom-media div:first-child{
        margin-left:100px;
    }
    .footer-bottom-web{
        display: flex;
    }
    .footer-bottom-mobile{
        display: none;
    }
}
@media screen and (max-width:550px){
    .footer-main-content{
        flex-direction: column;
    }
    .footer-category-navigate{
        padding-right:0
    }
    .footer-category{
        width: 100%;
    }
    .footer-category ul{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .footer-category ul li{
        width: 50%;
    }
}
.footer-bottom-media div:first-child{
    /* margin-left:800px; */
    background-color: #3B5998;
}
.footer-bottom-media div:nth-child(2){
    background-color: #D71A4D;
}
.footer-bottom-media div:nth-child(3){
    background-color: #F26522;
}
.footer-bottom-media div:nth-child(4){
    background-color: #1DA1F2;
}
.footer-bottom-media div:nth-child(5){
    background-color: #818A91;
}