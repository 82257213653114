.Search{
    display: flex;
    align-items: center;
    /* width: 100%; */
    width: 100%;
    /* max-width: 650px; */
    height: 50px;
    border: 2px solid;
    border-radius: 5px;
    border-color: #6C757D;
    box-sizing: border-box;
    /* margin-left:50px; */
    /* background-color: #6C757D;; */
    
}
.Search select{
    /* -webkit-appearance: none; */
    background-color: white;
    outline: none;
    border: none;
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    height: 40px;
    /* -webkit-appearance: none;
    background-color: white;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAJCAYAAAA/33wPAAAAvklEQVQoFY2QMQqEMBBFv7ERa/EMXkGw11K8QbDXzuN4BHv7QO6ifUgj7v4UAdlVM8Uwf+b9YZJISnlqrfEUZVlinucnBGKaJgghbiHOyLyFKIoCbdvecpyReYvo/Ma2bajrGtbaC58kCdZ1RZ7nl/4/4d5EsO/7nzl7IUtodBexMMagaRrs+06JLMvcNWmaOv2W/C/TMAyD58dxROgSmvxFFMdxoOs6lliWBXEcuzokXRbRoJRyvqqqQvye+QDMDz1D6yuj9wAAAABJRU5ErkJggg==);
    background-position : right center;
    background-repeat: no-repeat;
    padding-right: 1.5em; */
}
.Search button,input{
    outline: none;
    border: none;
    box-sizing: border-box;
}

.Search button:before{
    content:'';
    font-size: 13px;
    color: #ED1C24;
}
.Search button{
    background-color: white;
    border:2px;
    /* width: 40px; */
    flex-grow: 1;
    /* background-color: black; */
    height:42px;
    top: 0;
    right: 0;
    box-sizing: border-box;
}
.Search button:hover{
    cursor: pointer;
}
.Search input{
    background:white;
    border-radius: 5px 0px 0px 5px;
    /* width: 300px; */
    width:30vw;
    flex-grow: 9;
    height: 36px;
    padding-left: 15px;
    /* color:#6C757D; */
    top:0;
    right: 0;
}