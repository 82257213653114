.mainpage{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainpage div{
    flex-grow: 1;
}
.mainpage-featproduct{
    margin-top: 20px;
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainpage-featproduct h1{
    font-weight: bold;
    font-size: 36px;
}
.mainpage-featproduct-listing{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap:20px;
}
.loading-image{
    width: 100%;
    display: flex;
    justify-content: center;
}
.loading-image img{
    max-width: 200px;
    max-height: 200px;
}
.mainpage-break-banner{
    width: 100%;
    height: 100%;
    max-width: 1200px;
    max-height: 200px;
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainpage-break-banner img{
    max-width: 100%;
    max-height: 100%;
    /* max-height: 200px; */
}
.mainpage-subsrcibe{
    margin-top: 30px;
    width: 100vw;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(108,136,46);
}
.mainpage-subsrcibe h1{
    color: white;
    font-weight: normal;
    font-size: 40px;
    margin-bottom: 10px;
}
.mainpage-subsrcibe-form{
    display: flex;
    margin-bottom: 75px;
}
.mainpage-subsrcibe-form input{
    /* min-width: 470px; */
    height: 45px;
    padding-left: 20px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
}
.mainpage-subsrcibe-form button{
    /* min-width: 165px; */
    height: 45px;
    color: white;
    font-weight: bold;
    background-color: rgb(129,194,68);
    border: none;
    border-radius: 4px;
}
.mainpage-subsrcibe-form button:hover{
    background-color: rgb(132,175,75);
}
.mainpage-blog{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.mainpage-blog-listing{
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 801px) {
    .mainpage-subsrcibe-form input{
        min-width: 470px;
    }
    .mainpage-subsrcibe-form button{
        min-width: 165px;
    }
}
@media screen and (max-width: 800px) {
    .mainpage-featproduct{
        padding-left: 10px;
        padding-right: 10px;
    }
    .mainpage-featproduct h1{
        font-weight: normal;
        font-size: 30px;
    }
    .mainpage-featproduct-listing{
        gap:10px;
    }
    .mainpage-break-banner{
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .mainpage-subsrcibe h1{
        margin-top: 0;
        font-size: 30px;
    }
    .mainpage-subsrcibe-form{
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        margin: 0;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:10px;
    }
    .mainpage-subsrcibe-form input{
        width: 100%;
        margin: 0;
    }
    .mainpage-subsrcibe-form button{
        width: 100%;
        margin: 0;
    }
}