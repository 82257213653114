.faqpage{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.faqpage-content{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.faqpage-content a{
    color:rgb(129,194,68);
    text-decoration: underline;
}
.faqpage-content a:hover{
    color:rgb(108,136,46);
}
.faqpage-para h2{
    margin-bottom: 5px;
    font-size: 16px;
}
.faqpage-para p{
    margin-top:0;
    font-size: 14px;
    line-height: 20px;
}
.faqpage-para-sub h3{
    font-size: 16px;
    padding-left: 20px;
}
.faqpage-para-sub p{
    padding-left: 40px;

}
@media screen and (max-width: 800px) {
    .faqpage-content{
        padding-left: 10px;
        padding-right: 10px;
    }
}