.detailpagefield{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.detailpage{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    align-items: center;

    /* background-color: green; */
}
.detailpage-route a{
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    font-size: 12px;
}
.detailpage-route a:last-child{
    color: rgb(129,194,68);
}
.detailpage-route p{
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    font-size: 12px;
}
.detailpage-main{
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: left;
    align-items: flex-start;
    /* background-color: yellow; */
}
.detailpage-main-info{
    width: calc((100% - 10px) / 2);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.detailpage-main-info p{
    color: rgb(122,122,122);
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.detailpage-main-info-header{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    /* background-color: black; */
}
.detailpage-main-info-header p:first-child{
    width: 50px;
    height: 25px;
    border-radius: 4px;
    background-color: rgb(187,84,81);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
}
.detailpage-main-info-header p:nth-child(2){
    min-width: 70px;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
    border-radius: 4px;
    background-color: rgb(129,194,68);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
}
.detailpage-wishlist{
    margin-left: auto;
    background-color: transparent;
}
.detailpage-wishlist-dropdown.active{
    display: block;
}
.detailpage-wishlist-dropdown{
    display: none;
    position: absolute;
    right:0;
    background-color: white;
    box-shadow: 0 2px 10px black;
}
.detailpage-wishlist-dropdown li{
    /* list-style:none;
    text-align: start;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    margin-bottom: 10px;
    font-size: 12px; */
    width: 100%;
    height: 30px;
}
.detailpage-wishlist-dropdown button{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: right;
    color: black;
    background-color: transparent;

    border:none;
    font-size: 14px;
}
.detailpage-wishlist-dropdown li{
    color: black;
}
.detailpage-wishlist-dropdown button:hover{
    color: rgb(129,194,68);
    cursor: pointer;
}
.detailpage-wishlist-dropdown ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:0px 10px;
}
.wishlist-heart{
    margin-left: auto;
    background-color: transparent;
    font-size: 20px;
    border: none;
    color: rgb(170,172,174);
}
.wishlist-heart:hover{
    cursor: pointer;
}
.detailpage-main-info h1{
    font-weight: normal;
}
.detailpage-main-info-reviews{
    max-width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.detailpage-main-info-reviews p{
    margin-right: 10px;
}
.detailpage-main-info-reviews p:nth-child(2){
    font-size: 14px;
    color: rgb(170,172,174);
}
.detailpage-main-info-reviews p:nth-child(3){
    font-size: 14px;
    color: rgb(170,172,174);
}
.detailpage-main-info-reviews a{
    color: rgb(129,194,68);
    font-size: 14px;
}
.detailpage-main-info-price{
    max-width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 10px;
}
.detailpage-main-info-price p:first-child{
    font-size: 14px;
    color: rgb(170,172,174);
    margin-right: 5px;
}
/* .detailpage-main-info-price p:nth-child(2){
    font-size: 22px;
    text-decoration: line-through;
    margin-right: 10px;
    color:black
} */
.detailpage-main-info-price p.origin-line-through{
    font-size: 22px;
    text-decoration: line-through;
    margin-right: 10px;
    color:black
}
.detailpage-main-info-price p.origin-price{
    font-size: 22px;
    margin-right: 10px;
    color:rgb(129,194,68);
}
/* .detailpage-main-info-price p:nth-child(3){
    display: flex;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    background-color: black;
    color: white;
    margin-right: 10px;
} */
.detailpage-main-info-price p.sale-price{
    display: flex;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    background-color: black;
    color: white;
    margin-right: 10px;
}
/* .detailpage-main-info-price p:nth-child(4){
    font-size: 14px;
    color:black;
} */
.detailpage-main-info-price p.saved-price{
    font-size: 14px;
    color:black;
}
.detailpage-main-info-order{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 10px;
}
.detailpage-main-info-order-add{
    background-color: rgb(129,194,68);
    height: 60px;
    width: calc(100% - 100px - 15px);
    border: none;
    border-radius: 4px;
    font-size: 18px;
    color:white;
    font-weight: bold;
}
.detailpage-main-info-order-add.disabled{
    background-color: rgb(190,190,190);
}
.detailpage-main-info-order-add.disabled:hover{
    cursor: auto;
    background-color: rgb(190,190,190);
}
.detailpage-main-info-order-add:hover{
    background-color: rgb(108,136,46);
}
.detailpage-main-info-order-quantity{
    display: flex;
    margin-right: 15px;
}
.detailpage-main-info-order-quantity button{
    width: 30px;
    height: 60px;
    font-size: 16px;
    border: none;
    background-color: rgb(220,220,220);
    color: black;
    font-weight: normal;
}
.detailpage-main-info-order-quantity button:first-child{
    border-radius: 4px 0 0 4px;

}
.detailpage-main-info-order-quantity button:nth-child(3){
    border-radius: 0 4px 4px 0;
}
.detailpage-main-info-order-quantity input{
    width: 40px;
    height: 60px;
    font-size: 16px;
    text-align: center;
    font-weight: normal;
    border: 1px solid rgb(220,220,220);
    box-sizing: border-box;
    background-color: white;
}
.detailpage-information{
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.detailpage-information-nevigate{
    width: 100%;
    max-width: 1200px;
    /* border-bottom: 2px solid rgb(220,220,220); */
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding:0px;
    overflow: hidden;
}
.detailpage-information-nevigate-fixed
{
    position: fixed !important;
    top: 0;
    background-color: white;
}
.detailpage-information-nevigate table{
    width: 1200px;
    height: 50px;
    border-bottom: 2px solid rgb(220,220,220);
    border-collapse: collapse;
}
.detailpage-information-nevigate a{
    color:rgb(220,220,220);
    text-wrap: nowrap;
    font-size: 20px;
    font-weight: bold;
}
.detailpage-information-nevigate a.active{
    color:rgb(108,136,46);
}
.detailpage-information-nevigate button{
    color:rgb(220,220,220);
    background-color: white;
    border: none;
    text-wrap: nowrap;
    font-size: 20px;
    font-weight: bold;
}

.detailpage-information-nevigate button.active{
    color:rgb(108,136,46);
}
.detailpage-information-nevigate td{
    margin: 0px;
    width: fit-content;
    padding: 10px;
    height: 50px;
    border-bottom: 2px solid rgb(220,220,220);
}
.detailpage-information-nevigate td.active{
    border-bottom: 2px solid rgb(108,136,46);
}
.detailpage-information-nevigate table td:last-child{
    width: 100%;
}
.detailpage-information-description{
    border-bottom: 1px solid rgb(220,220,220);
}
.detailpage-information-description p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
}
.detailpage-information-details{
    border-bottom: 1px solid rgb(220,220,220);
}
.detailpage-information-details table{
    border: 1px solid rgb(220,220,220);
    border-collapse: collapse;
    margin-bottom: 40px;
}
.detailpage-information-details td{
    max-width: 100%;
    border: 1px solid rgb(220,220,220);
    padding: 10px 20px 10px 20px;
}
.detailpage-information-warranty{
    border-bottom: 1px solid rgb(220,220,220);
}
.detailpage-information-warranty p {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
}
.detailpage-information-reviews{
    border-bottom: 1px solid rgb(220,220,220);
}
.detailpage-information-reviews button {
    width: 350px;
    height: 40px;
    background-color: rgb(129,194,68);
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 40px;
}
.detailpage-information-reviews button:hover{
    background-color: rgb(108,136,46);
}
@media screen and (max-width: 800px) {
    .detailpage{
        padding-left: 10px;
        padding-right: 10px;
    }
    .detailpage-main{
        flex-direction: column;
    }
    .detailpage-main-info{
        width: 100%;
    }
    .detailpage-main-info h1{
        font-size: 24px;
    }
    .detailpage-information-nevigate{
        display: none;
    }
    .detailpage-information h1{
        font-size: 20px;

    }
    .detailpage-information-main{
        margin-top: 0px !important;
    }


}