.categorypage{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.categorypage h1{
    width: 100%;
    max-width: 1200px;
    margin-top: 0;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}
.categorypage-main{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display:flex;
}
.categorypage-main-multi{
    width: 30%;
    max-width: 350px;
}
.categorypage-content{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}
.categorypage-content img{
    width: 100%;
    max-height: 240px;
}
.categorypage-layout{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    /* background-color: green; */
}
.categorypage-layout button{
    width: 40px;
    height: 40px;
    padding:0;
    color:rgb(108, 108, 108);
    background-color: white;
    border: 1px solid rgb(220,221,222);
    border-radius: 4px;
    margin-right: 10px;
}
.categorypage-layout button.active{
    color:white;
    background-color: rgb(129,194,68);
}
.categorypage-select{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}
.categorypage-select p{
    font-size: 14px;
    font-weight: bold;
}
.categorypage-select select{
    border: 1px solid rgb(220,221,222);
    border-radius: 4px;
    padding-left: 20px;
    display: flex;
    width: 200px;
    height: 40px;
    text-align: left;
    margin-left: 10px;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
    box-sizing: border-box;
}
.categorypage-listing{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap:5px;
}
.categorypage-page{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
}
.categorypage-page button {
    background-color: white;
    min-width: 40px;
    height: 40px;
    border: none;
    margin-right: 5px;
    border-radius: 4px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
}
.categorypage-page button.active{
    color:white;
    background-color: rgb(129,194,68);
}
.categorypage-page button:hover{
    color:white;
    background-color: rgb(129,194,68);
}
.categorypage-layout button:last-child{
    display: none;
}
@media screen and (max-width: 800px) {
    .categorypage h1,.categorypage-main{
        padding-left: 10px;
        padding-right: 10px;
    }
    .categorypage-main-multi{
        width: fit-content;
    }
    .categorypage-content{
        width: 100%;
    }
    .categorypage-layout button{
        display: none;
    }
    .categorypage-select{
        margin-left: 0;
    }
    .categorypage-layout{
        box-sizing: border-box;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .categorypage-layout button:last-child{
        display: flex;
        width: 50%;
        margin-left: auto;
        margin-right: 0;
        align-items: center;
        border: 2px solid black;
        color: black;
        font-size: 16px;
        justify-content: center;
    }
}