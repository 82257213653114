.ourlocation{
    width: 100%;
    max-width: 1200px;
    box-sizing: border-box;
    padding: 30px 0 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.ourlocation-main{
    width: 100%;
    box-sizing: border-box;
    padding:0 30px 0 30px;
    display: flex;
    flex-direction: column;
}
.ourlocation-location{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding: 0 30px 0 30px;
    justify-content: space-between;
    padding-top: 30px;
}
.ourlocation-location iframe{
    width: 400;
    height: 300;
}
.ourlocation-location h2{
    margin-top: 0;
}
.ourlocation-location-info{
    display: flex;
    flex-direction: column;
    gap:30px
}
.ourlocation-location-info-block{
    display: flex;
    gap: 20px;
}
.ourlocation-location-info-block p {
    margin: 0;
}
.ourlocation-location-info-text{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.ourlocation-location-info-text a{
    color: rgb(129,194,68);

}
.ourlocation-location-info-text a:hover{
    color:rgb(108,136,46)
}
@media screen and (max-width:800px) {
    .ourlocation-main{
        padding-left: 10px;
        padding-right: 10px;
    }
    .ourlocation-location{
        flex-direction: column;
    }
    .ourlocation-location-info{
        gap:10px
    }
    .ourlocation-location-info-iframe{
        width: 100%;
        display: flex;
        box-sizing: border-box;
        padding-top:30px;
        justify-content: center;
        align-items: center;
    }
}