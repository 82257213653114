.header{
    /* margin-top: -8px;
    margin-left: -2px; */
    position:relative;
    width: 100vw;
}
.header-top-bar{
    display: flex;
    width:100vw;
    height:50px;
    background-color: rgb(245,245,245);
    justify-content: center;
    align-items: center;
}
.header-top-bar div,.header-middle-bar div, .header-main-navigate div{
    flex-grow: 1;
}

.header-media{
    display: flex;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    margin-left: 30px;
}
.header-media div{
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    /* margin-right:10px; */
    /* height:30px;
    width: 30px;    
    border-radius: 50%; */
    flex-grow: 0;
}
.header-media a{
    display: flex;
    max-width: 1200px;
    justify-content: left;
    align-items: center;
    color: rgb(129,194,68);
    margin-left: 10px;
}
.header-media a:hover{
    color: rgb(108,136,46);
}
/* .header-media a:first-child{
    margin-left: -150px;
    background-color: black;
} */
.header-media::before{
    content: "";
}
.header-middle-bar{
    display: flex;
    width:100vw;
    height:fit-content;
    justify-content: center;
    align-items: center;
}

.header-middle-content{
    width:100%;
    display: flex;
    max-width:1200px;
    height:fit-content;
    padding: 20px 30px 20px 30px;
    gap: 20px;
    box-sizing: border-box;
    justify-content: flex-start;
    align-items: center;
}
.header-middle-mobile-top{
    width: calc((100% - 100px) / 4);
    display: flex;
}
.header-middle-mobile-bottom{
    width: calc((100% - 100px) / 2);
    max-width: 650px;
    margin-left: 50px;
    margin-right: 30px;
}



@media screen and (max-width: 800px) {
    .header-top-bar{
        display: none;
    }
    .header-middle-content{
        max-width: 800px;
        display: flex;
        flex-direction: column;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .header-middle-mobile-top{
        width: 100%;
        max-width: 800px;
        padding-right: 15px;
        box-sizing: border-box;
    }
    .header-middle-content-mobile{
        width: 30px;
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap:10px;
        margin-right: 10px;
        box-sizing: border-box;
        flex-grow: 0!important;
    }
    .header-middle-content-mobile button{
        background-color: white;
        font-size: 24px;
        border:none;
    }
    .header-middle-mobile-bottom{
        width: 100%;
        max-width: 800px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
    }
    .header-nav-bar-toggle{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border: none;
        justify-content: flex-start;
        color: rgb(90,90,90);
        font-weight: bold;
    }
    .header-nav-bar-toggle p {
        padding: 0;
        margin: 0;
        margin-top: -10px;
    }
    .header-main-navigate{
        width: 100vw;
        max-width: 800px;
        height: auto;
        min-height: 100vh;
        position: absolute;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        top:0;
        left:0;
        background-color: rgba(0,0,0,0.7);
        z-index: 999;
        overflow: auto;
    }
    .header-main-navigate div{
        flex:0 0 auto;
    }
    .header-main-navigate-content{
        width:50vw;
        max-width: 360px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: white;
        overflow: auto;
        z-index: 999;
    }
    .header-main-navigate-mobile-title{
        width: 100%;
        max-width: 360px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
    }
    .header-main-navigate-mobile-title button{
        font-size: 40px;
        padding: 0;
        padding-top: 12px;
        margin-left: auto;
        box-sizing: border-box;
        background-color: white;
        border: none;
        text-align: center;
    }
    .header-main-navigate-mobile-user{
        width: 100%;
        max-width: 360px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 10px;
    }
    .header-main-navigate-mobile-user p{
        margin: 0;
        color: rgb(129,194,68);
        font-size: 20px;
        font-weight: bold;
        box-sizing: border-box;
    }
    .header-main-navigate-mobile-user p:hover{
        color: rgb(108,136,46);
        cursor: pointer;
    }
    .header-navigate{
        width: 100%;
        max-width: 360px;
        overflow: auto;
    }
    .header-navigate-link-main{
        width: 100%;
        max-width: 360px;
        background-color: rgb(220,221,222);
        padding: 0px 20px 0px 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .header-navi-subcategory{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .header-navigate-category,.header-navigate-link-button{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
    }
    .header-navigate-category button,.header-navigate-link-button button{
        font-size: 16px;
        height: 50px;
        background-color: transparent;
        border: none;
        font-weight: bold;
        color: rgb(90,90,90);
        text-align: left;
    }
    .header-navigate-category button{
        width: calc((100% / 4 * 3));
    }
    .header-navigate-link-button button{
        width: 100%;
    }
    .header-navigate-category button:hover,.header-navigate-link-button button:hover{
        color: black;
    }
    .header-navigate-category button:nth-child(2){
        width: calc((100% / 4));
        min-width: fit-content;
        height: 50px;
        color: rgb(160,160,160);
        font-size: 14px;
        margin-left: auto;
        text-align: right;
    }
    .header-navigate-category button.active{
        color: black;
    }
    .header-navigate-category button:nth-child(2):hover{
        color: black;
    }
    .header-navigate-link-button{
        width: 100%;
    }
    .header-navi-dropdown{
        width: 100%;
    }
    .header-navi-dropdown li{
        margin: 0;
    }
    .header-navi-dropdown li{
        color: rgb(90,90,90);
        font-size: 14px;
        display: flex;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .header-navi-dropdown li:hover{
        color: black;
        cursor: pointer;
    }
    .header-navi-dropdown.hide{
        display: none;
    }
}
@media screen and (max-width: 550px){
    .header-main-navigate-mobile-user p{
        font-size: 16px;
    }
    .header-navigate-link-main{
        padding: 0 10px 0 10px;
    }
    .header-navigate-link-button button{
        font-size:14px;
    }
    .header-navigate-category button:first-child{
        font-size:14px;
    }
}
@media screen and (min-width:801px){
    .header-middle-content-mobile{
        display: none;
    }
    .footer-main-content {
        flex-wrap: wrap;
    }
    .header-navigate-contact{
        display: flex;
        /* width: 400px; */
        justify-content: center;
        align-items: center;
    }
    .header-navigate-contact li{
        font-size: 12px;
    }
    .header-navi-subcategory:hover .header-navi-dropdown{
        display: block;
    }
    .header-nav-bar-toggle{
        display: none;
    }
    .header-navigate-category-mobile{
        display: none!important;
    }
    .header-main-navigate{
        display:flex;
        align-content: center;
        justify-content: center;
        height: fit-content;
        width: 100vw;
        z-index: 999;
        background-color: rgb(220,221,222);
    }
    .header-main-navigate-content{
        width: 100%;
        height: fit-content;
        display:flex;
        justify-content: flex-start;
        max-width:1200px;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
    }
    .header-navigate{
        height: fit-content;
        display:flex;
        justify-content: flex-start;
        align-items: center;
    
    }
    /* .header-logo{
        flex-grow:1
    } */
    /* .header-navigate ul{
        display:flex;
        flex-grow: 10;
    }
    .header-navigate li{
        display:block;
        flex:1;
    }
    
    .header-navigate ul,li{ 
        padding:0;
        margin:0;
        list-style:none;
        color:white;
        font-family:Arial;
        font-size: 12px;
    } */
    .header-navigate-link{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        /* background-color: green; */
    }
    .header-navigate-link-contact{
        flex-grow: 3;
        width:0;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        height:93px;
    }
    .header-navigate-link-main .header-navigate-link{
        flex-grow: 8;
        width:0;
    }
    .header-navigate-search{
        margin-left:-30px;
        flex-grow: 3;
        width:0;
    }
    
    .header-navigate-link div{
        flex-grow: 1;
    }
    .contact-link{
        font-size:15px;
    }
    .header-navigate-link button:hover{
        border-bottom: white solid 2px;
    }
    .header-navigate-link-contact button:hover{
        border-bottom: white solid 2px;
    }
    .header-main-navigate-mobile-title{
        display: none;
    }
    .header-main-navigate-mobile-user{
        display: none;
    }
    
    .header-navi-dropdown{
        display: none;
        position: absolute;
        background-color: white;
        box-shadow: 0 2px 10px black;
    }
    .header-navi-dropdown li{
        list-style:none;
        text-align: start;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .header-navi-dropdown li{
        color: black;
    }
    .header-navi-dropdown li:hover{
        color: rgb(129,194,68);
        cursor: pointer;
    }
    .header-navi-dropdown ul{
        padding:0px 10px;
    }
    .header-navigate-link button{
        display: flex;
        background-color: rgb(220,221,222);
        height:55px;
        color:black;
        width:fit-content;    
        font-size:14px;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        border: none;
    }
    .header-navigate-link-contact button{
        font-size: 15px;
    }
    .header-navigate-link-contact button:hover{
        cursor: pointer;
    }
    .header-navigate-link button:hover{
        cursor: pointer;
    }
    .header-banner{
        height:auto;
        width:99vw;
        border:none;
    }
    .header-banner img{
        border:none;
        width:100vw;
        /* width: 100%; */
        height: 100%;
        background-position: 50%;
        -webkit-background-size: cover;
        background-size: cover;
    }
    .header-title{
        display: flex;
        width: 100vw;
        font-family: "Roboto", Sans-serif;
        justify-content: center;
        align-items: center;
        border-bottom: solid 2px #c9c9c9;
        color: #ED1C24;
    }
    
}