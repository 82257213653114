.cartpage{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cartpage-content{
    width: 100%;
    max-width: 1200px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.cartpage h1{
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(220,221,222)
}
.cartpage-empty{
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
}
.cartpage-main{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    gap:30px;
}
.cartpage-list{
    /* width:100%; */
    width: calc((100% - 30px) / 3 * 2);
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    border-bottom: 1px solid rgb(220,221,222);
    padding-bottom: 20px;
    gap:15px
}
.cartpage-checkout{
    /* width: 100%; */
    width: calc((100% - 30px) / 3);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.cartpage-checkout-button{
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    background-color: rgb(111,197,84);
    border: none;
    border-radius: 5px;
    outline: 1px solid red;
}
.cartpage-checkout-button.disabled{
    outline: 1px solid rgb(240,240,240)!important;
    background-color: rgb(240,240,240)!important;
}
.cartpage-checkout-button:hover{
    background-color: white;
    color: rgb(111,197,84);
}
.cartpage-pricelist{
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    border: 1px solid rgb(220,221,222);
    box-sizing: border-box;
}
.cartpage-pricelist p {
    font-size: 14px;
}
.cartpage-pricelist-subtotal{
    display: flex;
    justify-content: left;
    align-items: center;
    border-bottom: 1px solid rgb(220,221,222);
}
.cartpage-pricelist-subtotal p:nth-child(2){
    margin-left: auto;
}
.cartpage-pricelist-shipping{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid rgb(220,221,222);
}
.cartpage-pricelist-shipping div:first-child{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.cartpage-pricelist-shipping div:first-child button{
    background-color: white;
    border:none;
    padding: 0;
    margin-left: auto;
    color:rgb(129,194,68);
}
.cartpage-pricelist-shipping div:first-child button:hover{
    color:rgb(108,136,46);
}
.cartpage-pricelist-shipping-address{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.cartpage-pricelist-shipping-address div{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.cartpage-pricelist-shipping-address div p {
    width: 45%;
    font-size: 14px;
}
.cartpage-pricelist-shipping-address select{
    border: 1px solid rgb(220,221,222);
    border-radius: 4px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    display: flex;
    width: 55%;
    max-width: 200px;
    text-align: left;
    margin-left: auto;
    /** for the dropdown indicator */
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1em;
}
.cartpage-pricelist-shipping-address input{
    width: 55%;
    border: 1px solid rgb(220,221,222);
    border-radius: 4px;
    height: 40px;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    max-width: 200px;
    margin-left: auto;
    box-sizing: border-box;
}
.cartpage-pricelist-shipping-address input:focus{
    outline: 2px solid blue;
    outline-offset: 1px;
}
.cartpage-pricelist-shipping-address button{
    width: 200px;
    box-sizing: border-box;
    margin-top: 10px;
    height: 35px;
    background-color:rgb(129,194,68);
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}
.cartpage-pricelist-shipping-address button:hover{
    background-color: rgb(108,136,46);
}
.cartpage-pricelist-coupon{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border-bottom: 1px solid rgb(220,221,222);
    padding-bottom: 15px;
}
.cartpage-pricelist-coupon div{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.cartpage-pricelist-coupon div:first-child button{
    background-color: white;
    border:none;
    padding: 0;
    margin-left: auto;
    color:rgb(129,194,68);
}

.cartpage-pricelist-coupon div:first-child button:hover{
    color:rgb(108,136,46);
}
.cartpage-pricelist-coupon div:nth-child(2) button{
    background-color: rgb(129,194,68);
    margin-left: auto;
    border: none;
    color: white;
    font-weight: bold;
    padding:10px;
    border-radius: 5px;
}
.cartpage-pricelist-coupon div:nth-child(2) button:hover{
    background-color:rgb(108,136,46);
}
.cartpage-pricelist-coupon div:nth-child(2) input{
    width: 100%;
    border: 1px solid rgb(220,221,222);
    border-radius: 5px;
    margin-right: 10px;
    padding:10px;
    box-sizing: border-box;
}

.cartpage-pricelist-coupon div:nth-child(2) input:focus{
    outline: 2px solid blue;
    outline-offset: 1px;
}
.cartpage-pricelist-total{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.cartpage-pricelist-total h2{
    font-size: 20px;
}
.cartpage-pricelist-total h2:nth-child(2){
    margin-left: auto;
    color:rgb(129,194,68);
}
@media screen and (max-width: 800px) {
    .cartpage-content{
        padding-left: 10px;
        padding-right: 10px;
    }
    .cartpage-main{
        flex-direction: column;
    }
    .cartpage-list{
        width: 100%;
    }
    .cartpage-checkout{
        width: 100%;
    }
}