.imagegallery{
    width: calc((100% - 10px) / 2);
    max-height: 675px;
    display: flex;
    flex-direction: column;
}
.imagegallery button:hover{
    cursor: pointer;
}
.imagegallery-main{
    width: 100%;
    height: 520px;
    background-color: white;
    border: none;
}
.imagegallery-main img{
    max-width: 100%;
    max-height: 100%;
}
.imagegallery-list{
    width: 100%;
    min-height: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-bottom: 1px solid rgb(220,221,222); */

}
.imagegallery-imglist-main{
    width:calc(100% - 70px);
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
}
.imagegallery-imglist{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    gap:5px;
}
.imagegallery-imglist button:first-child{
    margin-left: 0px;
}
.imagegallery-imglist button:last-child{
    margin-right: 0px;
}
.imagegallery-list button.active{
    border-color: black;
}
.imagegallery-list-img{
    height: 100%;
    flex:0 0 auto;
    border: 2px solid white;
}
.imagegallery-list-img img{
    max-width: 100%;
    min-height: 100%;
}
.imagegallery-list-navi{
    width: 35px;
    height: 100%;
    font-size: 30px;
    background-color: white;
    border: none;
    color: rgb(220,221,222);
}
.imagegallery-list-navi.active{
    color: black;
}
.immagegallery-links{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    box-sizing: border-box;
    gap:20px;
}
.immagegallery-links a{
    font-size: 20px;
    color: black;

}
.immagegallery-links a:hover{
    color: rgb(108,136,46);
}
@media screen and (max-width: 800px) {
    .imagegallery{
        width: 100%;
    }
    .immagegallery-links{
        display: none;
    }
    .imagegallery-list{
        border: none;
    }
}