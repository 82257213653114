.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 50px;
  padding-top: 25px;
  margin-top: 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(39, 70, 132, 0.2); */
  background: #fff;
  /* overflow: hidden; */
}
.card button:first-child{
  width: 100%;
  display: flex;
  text-align: left;
  font-weight: bold;
  border: 0px;
  border-bottom: 1px;
  border-style: solid;
  border-color: rgb(220,221,222);
  background-color: white;
  /* margin-bottom: 20px; */
  padding-bottom: 10px;
  font-size: 20px;
}
.card button:first-child::after{
  content:'\25B2';
  margin-left: auto;
  color: rgb(190,190,190);
}
.card .current-value {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
/* .card .current-value label {
  display: inline-flex;
  width: 50px;
  font-size: 20px;
} */
.card .current-value input {
  margin: 0;
  width: calc((100% - 20px) / 3);
  max-width: 60px;
  height: 25px;
  font-size: 16px;
  color: black;
  padding: 5px;
  padding-left: 10px;
  margin-right: 10px;
  border: 1px solid rgb(220,221,222);
  border-radius: 5px;
  background: white;
}
.card .current-value button{
  background-color: white;
  width: calc((100% - 20px) / 3);
  max-width: 70px;
  min-width: fit-content;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid black;
}
.card .values {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 240px;
}
.card #slider {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 240px;
  height: 10px;
  background: white;
  border-radius: 5px;
  border: 1px solid rgb(220,221,222);
  background: white;
  cursor: pointer;
  
}
.card #slider #min {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 0;
  height: 10px;
  border-radius: 5px;
  border: 1px solid rgb(220,221,222);
  background: white;
}
.card #slider #min:hover::before {
  opacity: 1;
}
.card #slider #min:hover::after {
  opacity: 1;
}
/* .card #slider #min::before {
  opacity: 0;
  content: attr(data-content);
  display: block;
  position: absolute;
  top: -40px;
  right: -23px;
  width: 40px;
  padding: 3px;
  text-align: center;
  color: white;
  background: #274684;
  border-radius: 20px;
}
.card #slider #min::after {
  opacity: 0;
  content: "";
  display: block;
  position: absolute;
  top: -18px;
  right: -8px;
  border-top: 8px solid #274684;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
} */
.card #slider #min #min-drag {
  position: absolute;
  right: -7.5px;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgb(220,221,222);
  background: rgb(246,246,246);
  transition: all 0.3s;
}
.card #slider #min #min-drag:hover {
  box-shadow: 0 0 0 6px rgba(39, 70, 132, 0.2);
}
.card #slider #max {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 0;
  height: 10px;
  border-radius: 5px;
  border: 1px solid rgb(220,221,222);
  background: rgb(233,233,233);
}
.card #slider #max:hover::before {
  opacity: 1;
}
.card #slider #max:hover::after {
  opacity: 1;
}
/* .card #slider #max::before {
  opacity: 0;
  content: attr(data-content);
  display: block;
  position: absolute;
  top: -40px;
  right: -23px;
  width: 40px;
  padding: 3px;
  text-align: center;
  color: white;
  background: #274684;
  border-radius: 20px;
} */
/* .card #slider #max::after {
  opacity: 0;
  content: "";
  display: block;
  position: absolute;
  top: -18px;
  right: -8px;
  border-top: 8px solid #274684;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
} */
.card #slider #max #max-drag {
  position: absolute;
  right: -7.5px;
  top: -5px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid rgb(220,221,222);
  background: rgb(246,246,246);
  transition: all 0.3s;
}
.card #slider #max #max-drag:hover {
  box-shadow: 0 0 0 6px rgba(39, 70, 132, 0.2);
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
@media screen and (max-width: 550px) {
  .card .current-value{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:10px;
  }
  .card .current-value input{
    width: 100%;
    max-width: 100%;
    height: 35px;
    box-sizing: border-box;
    margin: 0;
  }
  .card button:first-child{
    font-size: 14px;
    
  }
}