.cartitem{
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.cartitem-iteminfo{
    width: 40%;
    display: flex;
    justify-content: left;
    align-items: center;
}
.cartitem img{
    width: 25%;
    max-width: 100px;
    max-height: 100%;
}
.cartitem a, .cartitem span{
    width: 75%;
    color: black;
    font-size: 14px;
    padding-left: 20px;
    box-sizing: border-box;
}
.cartitem a:hover{
    color:rgb(129,194,68);
}
.cartitem-pricing{
    width: 60%;
    display: flex;
}
.cartitem-quantity{
    width: fit-content;
    display: flex;
    padding-left: 8%;
    box-sizing: border-box;
}
.cartitem-quantity button{
    width: 30px;
    height: 45px;
    font-size: 16px;
    border: none;
    background-color: rgb(220,220,220);
    color: black;
    font-weight: normal;
}
.cartitem-quantity button.disabled{
    background-color: rgb(240,240,240);
}
.cartitem-quantity button:first-child{
    border-radius: 4px 0 0 4px;

}
.cartitem-quantity button:nth-child(3){
    border-radius: 0 4px 4px 0;
}
.cartitem-quantity input{
    width: 40px;
    height: 45px;
    font-size: 16px;
    text-align: center;
    font-weight: normal;
    border: 1px solid rgb(220,220,220);
    box-sizing: border-box;
    background-color: white;
}
.cartitem-quantity input.disabled{
    background-color: rgb(240,240,240);
    border: 1px solid rgb(240,240,240);
}
.cartitem p{
    padding-left: 8%;
    box-sizing: border-box;
    font-weight: bold;
}
.cartitem-remove{
    background-color: white;
    border: none;
    font-size: 12px;
    margin-left: auto;
}
.cartitem-remove:hover{
    color: rgb(129,194,68);
}

@media screen and (max-width: 800px) {
    .cartitem{
        flex-direction: column;
        gap:10px
    }
    .cartitem-iteminfo{
        width: 100%;
    }
    .cartitem-pricing{
        width: 100%;
    }
    .cartitem-quantity{
        padding-left: 0;
    }
    .cartitem p{
        padding-left: 20px;
    }
}